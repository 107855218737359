<template>
  <v-container grid-list-md>
    <v-layout wrap>
      <v-flex xs12 sm6 md6>
        <v-text-field v-model="announcement.title" :rules="titleRules" label="Title*" />
      </v-flex>
      <v-flex xs12 sm6 md6>
        <datetime-picker v-model="announcement.publishTime" />
      </v-flex>
      <v-flex xs12>
        <vue-editor v-model="announcement.body" :editor-toolbar="customToolbar" />
      </v-flex>
      <v-flex xs12>
        <v-checkbox v-model="announcement.active" label="Active" />
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { VueEditor } from 'vue2-editor'
import DatetimePicker from '../DatetimePicker.vue'

export default {
  components: {
    VueEditor,
    DatetimePicker,
  },
  props: {
    announcement: {
      type: Object,
      require: true,
      default: null,
    },
  },
  data() {
    return {
      customToolbar: [
        ['bold', 'italic', 'underline'],
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        [{ list: 'ordered' }, { list: 'bullet' }],
        [{ color: [] }, { background: [] }],
        ['link', 'code-block'],
      ],
      titleRules: [
        (v) => !!v || 'Title is required',
        (v) => (v && v.length <= 150) || 'Title must be less than 150 characters',
      ],
    }
  },
}
</script>
