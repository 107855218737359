<template>
  <v-container>
    <v-toolbar text color="white">
      <v-toolbar-title>Announcements</v-toolbar-title>
      <v-spacer />
      <v-btn color="teal white--text" @click="addNew"> New Announcement </v-btn>
      <v-dialog v-model="dialog" transition="dialog-bottom-transition">
        <v-card>
          <v-card-title class="teal">
            <span class="headline white--text">New Annoucement</span>
          </v-card-title>
          <v-form ref="form">
            <v-card-text>
              <announcement-form v-if="currentAnnouncement" :announcement="currentAnnouncement" />
            </v-card-text>
            <v-divider />

            <v-card-actions v-if="currentAnnouncement">
              <v-spacer />
              <v-btn color="teal darken-1" text @click="dialog = false">
                {{ $t('common.cancel') }}
              </v-btn>
              <v-btn
                v-if="!currentAnnouncement.id"
                color="teal white--text"
                :loading="isProcessing"
                @click="onCreate"
              >
                {{ $t('common.create') }}
              </v-btn>
              <v-btn v-else color="teal white--text" :loading="isProcessing" @click="onUpdate">
                {{ $t('common.save') }}
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-dialog>
    </v-toolbar>

    <v-data-table
      :headers="headers"
      :items="formattedAnnouncements"
      class="elevation-1"
      :items-per-page="10"
    >
      <template v-slot:item="props">
        <tr>
          <td>
            <div style="max-width: 200px;" class="text-truncate">
              {{ props.item.title }}
            </div>
          </td>
          <td>
            <v-chip :color="props.item.active ? 'green' : 'red'" outlined>
              {{ props.item.active }}
            </v-chip>
          </td>
          <td>
            <span v-if="props.item.publishTime">
              {{ props.item.publishTime | dateFormat('lll') }}
            </span>
          </td>
          <td>
            <span v-if="props.item.createTime">
              {{ props.item.createTime | dateFormat('lll') }}
            </span>
          </td>
          <td class="layout">
            <v-icon small class="mr-2" @click="editItem(props.item)"> edit </v-icon>
            <v-icon small @click="deleteItem(props.item)"> delete </v-icon>
          </td>
        </tr>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import AnnouncementForm from '../components/announcement/AnnouncementForm.vue'
import { db } from '../plugins/firebase'

export default {
  name: 'Announcements',
  components: {
    AnnouncementForm,
  },
  data() {
    return {
      dialog: false,
      newAnnouncement: {
        body: '',
        title: '',
        active: false,
        publishTime: new Date(),
      },
      currentAnnouncement: null,
      headers: [
        {
          text: 'Title',
          align: 'left',
          sortable: true,
          value: 'title',
        },
        {
          text: 'Active',
          align: 'left',
          sortable: false,
          value: 'active',
        },
        {
          text: 'Publish Time',
          align: 'left',
          sortable: true,
          value: 'publishTime',
        },
        {
          text: 'Created Time',
          align: 'left',
          sortable: true,
          value: 'createTime',
        },
        {
          text: 'Actions',
          align: 'left',
          sortable: false,
          value: 'name',
        },
      ],
      announcements: [],
      isProcessing: false,
    }
  },
  firestore: {
    announcements: db.collection('announcements'),
  },
  computed: {
    formattedAnnouncements() {
      return this.announcements
        .map((item) => ({
          ...item,
          id: item.id,
          publishTime: item.publishTime ? item.publishTime.toDate() : null,
          createTime: item.createTime ? item.createTime.toDate() : null,
        }))
        .sort((a, b) => b.createTime - a.createTime)
    },
  },
  methods: {
    hideDialog() {
      this.dialog = false
      this.currentAnnouncement = null
    },
    addNew() {
      this.currentAnnouncement = { ...this.newAnnouncement }
      this.dialog = true
    },
    async onCreate() {
      const valid = this.$refs.form.validate()
      if (!valid) {
        return
      }
      this.currentAnnouncement.createTime = new Date()
      await this.$firestoreRefs.announcements.add(this.currentAnnouncement)
      this.hideDialog()
      this.$snotify.success(this.$t('common.create_successfully'))
    },
    async onUpdate() {
      if (!this.$refs.form.validate()) {
        return
      }
      const saveObject = { ...this.currentAnnouncement }
      delete saveObject.id
      await this.$firestoreRefs.announcements.doc(this.currentAnnouncement.id).set(saveObject)
      this.hideDialog()
      this.$snotify.success(this.$t('common.save_successfully'))
    },
    editItem(item) {
      this.currentAnnouncement = { ...item }
      this.dialog = true
    },
    async deleteItem(item) {
      if (await this.$root.$confirm('Delete', 'Are you sure?', { color: 'primary' })) {
        await this.$firestoreRefs.announcements.doc(item.id).delete()
        this.$snotify.success(this.$t('common.delete_successfully'))
      }
    },
  },
}
</script>
