<template>
  <div class="datetime-wrapper">
    <VueCtkDateTimePicker v-model="innerValue" :label="label" :format="format" />
  </div>
</template>

<script>
import VueCtkDateTimePicker from 'vue-ctk-date-time-picker'
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css'
import moment from 'moment'

export default {
  components: {
    VueCtkDateTimePicker,
  },
  props: {
    label: {
      type: String,
      default: 'Publish datetime',
    },
    value: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      format: 'YYYY-MM-DD HH:mm',
      innerValue: null,
    }
  },
  watch: {
    value: {
      handler(newValue) {
        let valueStr = newValue
        if (newValue instanceof Date) {
          // use moment to deal with browser timezone
          valueStr = moment(newValue).format()
        }
        if (this.innerValue !== valueStr) {
          this.innerValue = valueStr
        }
      },
      immediate: true,
    },
    innerValue: {
      handler() {
        this.$emit('input', new Date(this.innerValue))
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.datetime-wrapper ::v-deep {
  #DateTimePicker {
    border: none;
  }

  .date-time-picker {
    border-bottom: 1px solid gray !important;
  }
}
</style>
